<template>
    <b-form-input type="text" name="single"></b-form-input>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

let startDate;

export default {
    name: 'datepicker',
    props: {
        start: {
            type: String
        },
        format: {
          type: String
        }
    },
    mounted () {
        if (this.start) {
            startDate = moment(this.start, this.props.format ? this.props.format : 'YYYY-MM-DD');
        } else {
            startDate = moment();
        }

        const onDateSelected = (start) => {
            this.$emit('picker', {
                startDate: start.format(this.props.format ? this.props.format : 'YYYY-MM-DD')
            });
        };
        $(function() {
            $('input[name="single"]').daterangepicker(
                {
                    "alwaysShowCalendars": true,
                    "startDate": startDate,
                    "singleDatePicker": true,
                    "minDate": moment(),
                    "maxDate": moment().add(30, 'days'),
                    locale: {
                        format: this.props.format ? this.props.format : 'YYYY-MM-DD'
                    }
                },
                onDateSelected
            );
        });
    },
}
</script>
