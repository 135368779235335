import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import getMAC from 'getmac'
export const BASE_ROUTE = '/'

let BASE_URL_ENDPOINT = process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG

axios.defaults.headers.common['latitude'] = localStorage.getItem('latitude')
axios.defaults.headers.common['longitude'] = localStorage.getItem('longitude')

const $server = axios.create({
  baseURL: BASE_URL_ENDPOINT,
  headers: {
    'Accept': 'application/json',
    Authorization: {
      toString () {
        return `Bearer ${localStorage.getItem('access_token')}`
      }
    },
    'Content-Type': 'application/json'
  }
})
$server.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.code == 'ECONNABORTED') {
    return error.code
  }
  if (error.response.status == 401) {
    if (error.response.data == 'Your credentials are incorrect. Please try again') {
      Swal.fire(
        'Failed!',
        error.response.data,
        'error'
      )
    } else {
      if (localStorage.getItem('access_token') != null) {
        localStorage.removeItem('access_token');
        Swal.fire(
          'Your session expired!',
          'Your session has expired. Please login again to access this page!',
          'error'
        ).then(() => {
          window.location = BASE_ROUTE + 'login';
        })
      }
    }
  } else if (error.response.status == 403) {
    window.location = BASE_ROUTE + '403';
  } else if (error.response.status == 500) {
    // window.location =  BASE_ROUTE + '500';
  }

  return Promise.reject(error)

})

export default $server
