import $server from '../../endpoint'

const state = () => ({
    authenticated: [], //MENAMPUNG USER YANG SEDANG LOGIN
    username: [],
    email: []
})

const mutations = {
  ASSIGN_USER_AUTH(state, payload) {
      state.authenticated = payload
  },
  ASSIGN_USER_NAME(state, payload) {
      state.username = payload
  },
  ASSIGN_USER_EMAIL(state, payload) {
    state.email = payload
  }
}

const actions = {
    //MENGAMBIL DATA USER YANG SEDANG LOGIN
    getUserLogin({ commit }) {
        return new Promise((resolve, reject) => {
          commit('setBusyPage', true, { root: true })
            $server.get(`user-authenticated`)
            .then((response) => {
                //SIMPAN DATA USER TERSEBUT
                commit('ASSIGN_USER_AUTH', response.data.data.permission)
                commit('ASSIGN_USER_NAME', response.data.data.name)
                commit('ASSIGN_USER_EMAIL', response.data.data.email)
                commit('ASSIGN_USER_EXPIRED_PASSWORD', response.data.data.password_expired, { root: true })
                commit('setBusyPage', false, { root: true })
                resolve(response.data)
            }).catch((error) => {
                if (error.response) {
                    reject(error)
                }
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
