import Vue from "vue";
import Vuex from "vuex";
import user from "./module/user";
import $server from "../endpoint";

Vue.use(Vuex);
// axios.defaults.baseURL = process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG

export const store = new Vuex.Store({
  modules: {
    user
  },
  state: {
    token: localStorage.getItem("access_token") || null,
    lat: localStorage.getItem("latitude") || null,
    long: localStorage.getItem("longitude") || null,
    mac: localStorage.getItem("mac_address") || null,
    pages: [],
    dinars: [],
    categoryJewelry: [],
    password_expired: false,
    busyPage: false
  },
  getters: {
    loggedIn(state) {
      return state.token != null;
    },
    location(state) {
      return state.lat != null && state.long != null;
    },
    password_expired(state) {
      return state.password_expired;
    }
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
    },
    retrieveMac(state, mac) {
      state.mac = mac;
    },
    destroyToken(state) {
      state.token = null;
    },
    ASSIGN_USER_EXPIRED_PASSWORD(state, payload) {
      state.password_expired = payload;
    },
    setBusyPage(state, payload) {
      state.busyPage = payload;
    }
  },
  actions: {
    destroyToken(context) {
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          $server
            .post(`logout`)
            .then(response => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("latitude");
              localStorage.removeItem("longitude");
              context.commit("destroyToken");
              context.commit("ASSIGN_USER_EXPIRED_PASSWORD", false);
              context.commit("ASSIGN_USER_STATUS", false);
              resolve(response);
            })
            .catch(error => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("latitude");
              localStorage.removeItem("longitude");
              context.commit("destroyToken");
              context.commit("ASSIGN_USER_EXPIRED_PASSWORD", false);
              context.commit("ASSIGN_USER_STATUS", false);
              reject(error);
            });
        });
      }
    },

    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        $server
          .post(`login`, {
            username: credentials.username,
            password: credentials.password,
            recaptcha_token: credentials.recaptchaToken,
            latitude: credentials.latitude,
            longitude: credentials.longitude
          })
          .then(response => {
            const token = response.data.access_token;

            localStorage.setItem("access_token", token);

            context.commit("retrieveToken", token);
            return resolve(response);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    retrieveMac(context, payload) {
      return new Promise(resolve => {
        localStorage.setItem("mac_address", payload.address);
        context.commit("retrieveMac", payload.address);
        resolve(payload);
      });
    }
  }
});
