<template>
  <b-form-input type="text" name="daterange"></b-form-input>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

let startDate;
let endDate;

export default {
  name: 'datepicker',
  props: {
    start: {
      type: String
    },
    end: {
      type: String
    },
    value: {
      type: String
    }
  },
  mounted () {
    if (this.start && this.end) {
      startDate = moment(this.start, 'YYYY-MM-DD HH:mm:ss');
      endDate = moment(this.end, 'YYYY-MM-DD HH:mm:ss');
    }

    const onDateSelected = (start, end) => {
      this.$emit('picker', {
        startDate: start.format('YYYY-MM-DD HH:mm:ss'),
        endDate: end.format('YYYY-MM-DD HH:mm:ss'),
      });
    };
    $(function () {
      $('input[name="daterange"]').daterangepicker(
        {
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          "alwaysShowCalendars": true,
          "startDate": startDate,
          "endDate": endDate,
          "timePicker": true,
          "timePicker24Hour": true,
          "timePickerSeconds": true,
          locale: {
            format: 'YYYY-MM-DD HH:mm:ss'
          }
        },
        onDateSelected
      );
    });
  },
}
</script>
