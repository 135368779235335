<template>
    <b-form-input type="text" name="daterange"></b-form-input>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

let startDate;

export default {
    name: 'datepicker',
    props: {
        start: {
            type: String
        }
    },
    data() {
        return {
            datas: ''
        }
    },
    mounted () {
        if (this.start) {
            startDate = moment(this.start, 'HH:mm');
        }else {
            startDate = moment().format('HH:mm');
        }
        const onDateSelected = (start) => {
            this.$emit('picker', {
                startDate: start.format('HH:mm'),
            });
        };
        $(function() {
            $('input[name="daterange"]').daterangepicker(
                {
                    "startDate": startDate,
                    "timePicker": true,
                    "singleDatePicker": true,
                    "timePicker24Hour": true,
                    "minDate": moment(),
                    locale: {
                        format: 'HH:mm'
                    }
                },
                onDateSelected
            );
        }).on('show.daterangepicker', function (ev, picker) {
            picker.container.find(".calendar-table").hide();
        });
    },
}
</script>
