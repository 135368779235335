<template>
  <b-form-input
    readonly
    :type="'text'"
    :name="inputName"
    style="background-color: transparent;"
    placeholder="Select a date"
  ></b-form-input>
</template>

<script>
import $ from "jquery";
import moment from "moment";

export default {
  name: "datepicker",
  props: {
    start: {
      type: String
    },
    end: {
      type: String
    },
    type: {
      type: String
    },
    disable: {
      type: Boolean
    },
    autoUpdate: {
      type: Boolean
    },
    timePicker: {
      type: Boolean
    },
    name: {
      type: String,
      default: "daterange"
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      disableds: this.disable,
      autoUpdates: this.autoUpdate !== undefined ? this.autoUpdate : true,
      timePickers: this.timePicker !== undefined ? this.timePicker : false,
      types: this.type,
      inputName: this.name,
      formats:
        this.timePicker !== undefined && this.timePicker
          ? "YYYY-MM-DD HH:mm:ss"
          : "YYYY-MM-DD"
    };
  },
  watch: {
    disable(newValue) {
      this.disableds = newValue;
      const datepicker = $(`input[name="${this.inputName}"]`);
      this.applyDatePickerLogic(datepicker);
    },
    start(newValue) {
      const format = this.formats;
      const datepicker = $(`input[name="${this.inputName}"]`);
      const isClear = this.types == "clear";

      if (!newValue) {
        this.startDate = moment()
          .subtract(6, "days")
          .format(format);
        const defaultStartDate = moment().subtract(6, "days");

        datepicker.data("daterangepicker").setStartDate(defaultStartDate);
      } else {
        this.startDate = moment(newValue).format(format);
        datepicker.data("daterangepicker").setStartDate(this.startDate);
      }

      if (!newValue && isClear) {
        this.handleFilled({
          start: null,
          end: null
        });
      }
    },
    end(newValue) {
      const format = this.formats;
      const datepicker = $(`input[name="${this.inputName}"]`);
      const isClear = this.types == "clear";

      if (!newValue) {
        this.endDate = moment().format(format);
        const defaultEndDate = moment();

        datepicker.data("daterangepicker").setEndDate(defaultEndDate);
      } else {
        this.endDate = moment(newValue).format(format);
        datepicker.data("daterangepicker").setEndDate(this.endDate);
      }

      if (!newValue && isClear) {
        this.handleFilled({
          start: null,
          end: null
        });
      }
    }
  },
  methods: {
    handleValue() {
      const format = this.formats;

      if (this.start && this.end) {
        this.disableds = this.disable;
        this.autoUpdates = this.autoUpdate;
        this.timePickers = this.timePicker;
        this.endDate = moment(this.end, format);
        this.startDate = moment(this.start, format);
      } else if (this.type == "clear" && !this.start && !this.end) {
        this.disableds = this.disable;
      } else {
        this.disableds = this.disable;
        this.endDate = moment().format(format);
        this.startDate = moment()
          .subtract(6, "days")
          .format(format);
      }
    },
    applyDatePickerLogic(datepicker) {
      const defaultStartDate = moment().subtract(6, "days");
      const defaultEndDate = moment();

      datepicker.prop("disabled", this.disableds && this.types);
      datepicker.data("daterangepicker").setStartDate(defaultStartDate);
      datepicker.data("daterangepicker").setEndDate(defaultEndDate);
    },
    handleParseDate(val) {
      return moment(val);
    },
    handleFilled(value) {
      const format = this.formats;
      const datepicker = $(`input[name="${this.inputName}"]`);

      if (value.start && value.end) {
        datepicker.val(
          `${value.start.format(format)} - ${value.end.format(format)}`
        );
      } else {
        datepicker.val("");
      }
    }
  },
  mounted() {
    this.handleValue();

    const onDateSelected = (start, end) => {
      const format = this.formats;

      if (start && end) {
        this.$emit("picker", {
          startDate: start.format(format),
          endDate: end.format(format)
        });

        if (this.types == "clear") this.handleFilled({ start, end });
      }
    };

    $(() => {
      const isTypeClear = this.types == "clear";
      const isTypeClearValue =
        this.autoUpdates || !(isTypeClear && !this.startDate && !this.endDate);

      const datepickerOptions = {
        ranges: {
          Today: [moment().startOf("day"), moment().endOf("day")],
          Yesterday: [
            moment()
              .subtract(1, "days")
              .startOf("day"),
            moment()
              .subtract(1, "days")
              .endOf("day")
          ],
          "Last 7 Days": [
            moment()
              .subtract(6, "days")
              .startOf("day"),
            moment().endOf("day")
          ],
          "Last 30 Days": [
            moment()
              .subtract(29, "days")
              .startOf("day"),
            moment().endOf("day")
          ],
          "This Month": [
            moment()
              .startOf("month")
              .startOf("day"),
            moment()
              .endOf("month")
              .endOf("day")
          ],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month")
              .startOf("day"),
            moment()
              .subtract(1, "month")
              .endOf("month")
              .endOf("day")
          ]
        },
        alwaysShowCalendars: true,
        readonly: true,
        autoUpdateInput: isTypeClearValue,
        startDate: this.startDate,
        endDate: this.endDate,
        locale: {
          applyLabel: "Apply Date",
          format: this.formats
        }
      };

      if (!this.types) datepickerOptions.maxDate = moment();
      if (this.timePickers) datepickerOptions.timePicker = true;

      const datepicker = $(`input[name="${this.inputName}"]`);

      datepicker.daterangepicker(datepickerOptions, (start, end) => {
        onDateSelected(start, end);
      });

      datepicker.on("apply.daterangepicker", (ev, picker) => {
        const start = picker.startDate;
        const end = picker.endDate;

        onDateSelected(start, end);
      });

      this.applyDatePickerLogic(datepicker);
    });
  }
};
</script>
