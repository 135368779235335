// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import App from './App'
import { store } from './store/request'
import router from './router/index'
import VueEvents from 'vue-events'
import $server from './endpoint'
import VueCurrencyFilter from 'vue-currency-filter'
import Toasted from 'vue-toasted'
import { mapActions, mapGetters } from 'vuex'
import BootstrapVue from 'bootstrap-vue/src/index.js'
import Permissions from '../src/store/mixins/permission'
import Username from '../src/store/mixins/username'
import Email from '../src/store/mixins/email'
import DatePicker from 'vue2-datepicker'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import DateRangePicker from './views/custom/RangePicker'
import Range from './views/custom/DateRange'
import Month from './views/custom/MonthRange'
import TransactionPicker from './views/custom/TransactionPicker'
import Time from './views/custom/TimeRange'
import SinglePicer from './views/custom/SinglePicker'
import Hour from './views/custom/HourMax'
import moment from 'moment/src/moment'
import VueQuillEditor from 'vue-quill-editor'
import vSelect from 'vue-select'
import pusher from 'vue-pusher'
import VueMask from 'v-mask'
// import axios from 'axios'

import 'sweetalert2/src/sweetalert2.scss'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: '100%'
  }
}

Vue.use(VueMask);
Vue.use(VueQuillEditor)
Vue.component('v-select', vSelect)
Vue.component('date-picker', DatePicker)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('range-picker', Range)
Vue.component('month-range-picker', Month)
Vue.component('transaction-picker', TransactionPicker)
Vue.component('time-picker', Time)
Vue.component('single-picker', SinglePicer)
Vue.component('hour-picker', Hour)
Vue.mixin(Username)
Vue.mixin(Email)
Vue.mixin(Permissions)
Vue.use(BootstrapVue)
Vue.use(Toasted, {
  duration: 3000,
})

Vue.use(VueCurrencyFilter,
  {
    symbol: 'Rp.',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })
var moments = require('moment')

// var quillStyle = axios.get(`./quillStyle.css`).then((res) => {
//   console.log(res.data);
// })
var quillStyle = 'lorem'

var bus = new Vue({});

Vue.prototype.quillStyle = quillStyle
Vue.prototype.moment = moments
Vue.prototype.$events = bus;

Vue.prototype.$swal = Swal;
Vue.use(pusher, {
  api_key: '52e99bd2c3c42e577e13',
  options: {
    cluster: 'ap1',
    forceTLS: true,
  }
});

Vue.prototype.$BASE_ROUTE = process.env.VUE_APP_BASE_URL_LINK + process.env.VUE_APP_BASE_ROUTE
Vue.prototype.$setDate = moment();

Vue.prototype.$http = $server;
Vue.use(VueEvents)


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }

  } else {
    next() // make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
  computed: {
    ...mapGetters(['loggedIn', 'location'])
  },
  methods: {
    ...mapActions('user', ['getUserLogin'])
  },
  data () {
    return {
      errors: {
        message: [],
        status: [],
        headers: [],
      },
    }
  },
  created () {
    // if (!this.trueMac) {
    //   this.$router.push("/403");
    // }
    // console.log('Finansialku' + process.env.VUE_APP_FINANSIALKU);
    // console.log('Ladara' + process.env.VUE_APP_LADARA);
    // console.log('Lazisnu' + process.env.VUE_APP_LAZISNU);
    // console.log('NU' + process.env.VUE_APP_NU);
    if (this.location && this.loggedIn) {
      // if (this.loggedIn) {
      this.getUserLogin().then(() => { }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.status;
          this.errors.headers = error.response.headers;
          if (this.errors.status == 500) {
            this.$router.push("/500");
          } else if (this.errors.code == 403) {
            this.$router.push('/403');
          } else if (this.errors.code == 401) {
            localStorage.removeItem('access_token')
            this.$router.push("/login")
          }
        }
      })
    }
  }

})
