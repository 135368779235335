<template>
    <b-form-input type="text" name="daterange"></b-form-input>
</template>

<script>
import $ from 'jquery';

import moment from 'moment';

let startDate;
let endDate;

export default {
    name: 'monthpicker',
    props: {
        start: {
            type: String
        },
        end: {
            type: String
        }
    },
    mounted () {
        if (this.start && this.end) {
            startDate = moment(this.start, 'YYYY-MM-DD');
            endDate = moment(this.end, 'YYYY-MM-DD');
        } else {
            startDate = moment().subtract(29, 'days');
            endDate = moment();
        }

        const onDateSelected = (start, end) => {
            this.$emit('picker', {
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD'),
            });
        };
        $(function() {
            $('input[name="daterange"]').daterangepicker(
                {
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    },
                    "maxSpan": {
                      "days": 29
                    },
                    "alwaysShowCalendars": true,
                    "startDate": startDate,
                    "endDate": endDate,
                    "maxDate": moment(),
                    locale: {
                        format: 'YYYY-MM-DD'
                    }
                },
                onDateSelected
            );
        });
    },
}
</script>
